import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { getUserEmail, requestResendVerifyEmail } from "../utils";
import { PrimaryButton } from "../styles/Button.styles";
import styled from "styled-components";
import PageTransition from "../components/PageTransition";
import { useUser } from "../context/UserContext";

const VerifyEmail = () => {
    const [verifyStage, setVerifyStage] = useState("");
    const [timeLeft, setTimeLeft] = useState(300);
    const [userEmail, setUserEmail] = useState("");

    const { setShowContactForm } = useUser();

    const fetchEmail = async () => {
        const res = await getUserEmail();
        if(res.email){
            setUserEmail(res.email)
        }
    }

    useEffect(() => {
        fetchEmail()
    }, [])

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => {
                setTimeLeft((prevTime) => Math.max(prevTime - 1, 0));
            }, 1000);
      
            return () => clearInterval(timer);
        }
    }, [timeLeft])

    const resendEmail = async () => {
        const res = await requestResendVerifyEmail();

        if(res.message === "Too soon"){
            setTimeLeft(() => {
                const now = new Date();
                const allowedTime = new Date(res.timeLeft);
                return Math.max((allowedTime - now) / 1000, 0);
            })
        } else if(res.message === "Email resent") {
            setVerifyStage("resent")
        }
    }

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = Math.floor(seconds % 60) < 10 ? "0" + Math.floor(seconds % 60) : Math.floor(seconds % 60);
        return `0${minutes}:${secs}`;
    };

    return (
        <>
                <Instructions>
                    {verifyStage === "resent" ? (
                        <>
                            <h1>Email has been resent.</h1>
                            <p>You're almost there! We have sent an email to this address: <b>{userEmail}</b></p>
                            <p>Re-check your emails and click on the link in the email. If you don't see it, you may need to check your spam folder.<br/>The link in the sent email will expire in 1 hour.</p>
                        </>
                    ) : (
                        <>
                            <h1>Please Verify your email.</h1>
                            <p>You're almost there! We have sent an email to this address: <b>{userEmail}</b></p>
                            <p>Just click on the link in that email to complete your sign up. If you don't see it, you may need to check your spam folder.<br/>The link in the sent email will expire in 1 hour.</p>
                        </>
                    )}
                    
                    <p className="subHeading">Still can't find the email?</p>
                    {timeLeft > 0 ? (
                        <p className="smallerText">You can resend the verification email in <span className="time">{formatTime(timeLeft)}</span></p>
                    ) : (
                        <p className="smallerText">You can only request another email after 5 minutes.</p>
                    )}
                    <PrimaryButton onClick={resendEmail}>Resend Email</PrimaryButton>
                    <p>Is the email mentioned above wrong? <Link to="/changeEmail" className="editEmailBtn">Change it here</Link></p>
                    <p>Need help? <span className="contactBtn" onClick={() => setShowContactForm(true)}>Contact Us</span></p>
                </Instructions>
            
            <PageTransition isLoading={false}/>
        </>
    )
}

export default VerifyEmail;

const Instructions = styled.div`
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    padding: 0 30px;

    h1, p {
        margin: 10px 0;
    }

    h1 {
        font-size: var(--font-medium);
    }

    p, .subHeading {
        font-size: var(--font-small);
    }

    .subHeading {
        margin: 16px 0 0;
    }

    .smallerText {
        margin: 0 0 10px;
        font-size: var(--font-small);
    }

    .time {
        font-family: monospace;
    }

    .contactBtn {
        color: var(--blue-dark);
        cursor: pointer;
    }

    .editEmailBtn {
        color: var(--blue-dark);
        cursor: pointer;
        text-decoration: none;
    }
`;