import styled from "styled-components";
import PageTransition from "../components/PageTransition.js";
import { FormButton } from "../styles/Button.styles.js";
import { FormInput } from "../styles/Form.styles.js";
import { useRef, useState } from "react";
import { editEmail } from "../utils/index.js";
import { Link } from "react-router-dom";

import { device } from "../styles/Responsive.style";

const ChangeEmail = () => {
    const emailRef = useRef();

    const [message, setMessage] = useState("");

    const handleResetEmail = async (e) => {
        e.preventDefault();

        const data = await editEmail(emailRef.current.value);

        if(data.message === "Email Updated"){
            setMessage("Success");
        } else {
            setMessage(data.message);
        }
    }

    return (
        <>
            {message === "Success" ? (
                <SuccessWrap>
                    <TickSymbol></TickSymbol>
                    <p>Email has been changed</p>
                    <VerifyBtn to="/verifyEmail">Ok</VerifyBtn>
                </SuccessWrap>
            ) : (
                <SectionBlock>
                    <ResetPasswordForm onSubmit={handleResetEmail}> 
                        <h1>Change Email</h1>
                        <FormInput>   
                            <input type="email" placeholder=" " ref={emailRef} required />
                            <label>Email</label>
                        </FormInput>
                        <Message>{message}</Message>
                        <FormButton type="submit">Submit</FormButton>
                    </ResetPasswordForm>
                </SectionBlock>
            )}
            <PageTransition isLoading={false} />
        </>
    )
}

export default ChangeEmail;

const SectionBlock = styled.section`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media ${device.small} {
        align-items: flex-start;
    }
`;

const ResetPasswordForm = styled.form`
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: var(--bg-main);
    padding: 50px;
    border-radius: 5px;

    h1 {
        margin: 0;
        font-size: calc(var(--font-large) * 1.4);
    }

    button[type="submit"] {
        margin: 10px 0 0 0;
    }

    @media ${device.small} {
        width: 100%;
        padding: 20px;
        margin: 50px 5px 0;
    }
`;

const Message = styled.p`
    color: red;
    margin: 0;
    height: 19px;
    margin-top: 10px;
`;

const SuccessWrap = styled.div`
    display: grid;
    place-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    background-color: var(--bg-main);
    padding: 50px;
    border-radius: 5px;
    width: 230px;
    margin: auto;

    p {
        text-align: center;
        margin: 20px 0 0 0;
        font-size: 20px;
    }

    @media ${device.small} {
        margin: 50px 5px 0;
    }
`;

const TickSymbol = styled.div`
    height: 100px;
    width: 100px;
    border: 3px solid var(--green);
    border-radius: 50%;
    position: relative;

    &::before {
        position: absolute;
        content: "";
        height: 52px;
        width: 3px;
        top: calc(50% + 3px);
        left: calc(50% + 12px);
        transform: translate(-50%, -50%) rotate(45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
    &::after {
        position: absolute;
        content: "";
        height: 28px;
        width: 3px;
        top: calc(50% + 12px);
        left: calc(50% - 15px);
        transform: translate(-50%, -50%) rotate(-45deg);
        border-radius: 5px;
        background-color: var(--green);
    }
`;

const VerifyBtn = styled(Link)`
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    background-color: var(--primary-gray);
    text-decoration: none;
    text-align: center;

    &:hover {
        background-color: var(--secondary-gray);
    }
`;